import { makeAutoObservable } from 'mobx';

import {
  I$WWrapper,
  MenuContext,
  MenuItem,
  MenuService as IMenuService,
  RouteConfiguration,
} from '../../../../types';
import { getLoginBarMenu } from '../util';
import { APP_WIDGET_LOGIN_MENU_ID } from '../../../../constants';

const USER_NAME_PATTERN = '{userName}';

const replaceUserNamePatternWithSlug = (item: MenuItem, slugOrId: string) => {
  if (!item.link?.includes(USER_NAME_PATTERN)) {
    return item;
  }

  const link = item.link.replace(USER_NAME_PATTERN, slugOrId);
  return { ...item, link };
};

const findRouteByMenuItem = (routes: RouteConfiguration[], item: MenuItem) =>
  routes.find((route) => item.link?.includes(route.path));

export const getIsTabAccessibleWithUserRoles = (
  currentUserRoles: string[],
  visibleForRoles: string[],
) => {
  if (!visibleForRoles.length) {
    return true;
  }

  return currentUserRoles.some((role) => visibleForRoles.includes(role));
};

const getInitialMenuItems = ($w: I$WWrapper) => getLoginBarMenu($w).menuItems;

export class MenuService implements IMenuService {
  menuItems: MenuItem[];
  private menuContext!: MenuContext;

  constructor(private readonly $w: I$WWrapper) {
    this.menuItems = getInitialMenuItems(this.$w);
    makeAutoObservable(this);
  }

  initializeMenuItems(menuContext: MenuContext) {
    this.menuContext = menuContext;

    if (this.menuContext.cacheService.hasLoginMenuItems()) {
      this.menuItems = this.menuContext.cacheService.getLoginMenuItems();
      this.bindMenuItems();
      return;
    }

    this.filterMenuItemsVisibleForRoles();
    this.fillMenuItemsWithSlugs();
    this.menuContext.cacheService.setLoginMenuItems(this.menuItems);
    this.bindMenuItems();
  }

  private bindMenuItems() {
    this.$w(APP_WIDGET_LOGIN_MENU_ID).menuItems = this.menuItems;
  }

  private filterMenuItemsVisibleForRoles() {
    const { routes } = this.menuContext;
    const { currentUserRoles } = this.menuContext;

    this.menuItems = this.menuItems.filter((item) => {
      const routeConfig = findRouteByMenuItem(routes, item);

      if (!routeConfig?.vfr?.length) {
        return true;
      }

      return getIsTabAccessibleWithUserRoles(currentUserRoles, routeConfig.vfr);
    });
  }

  private fillMenuItemsWithSlugs() {
    const { currentUser } = this.menuContext;
    const slugOrId = currentUser?.profile?.slug ?? currentUser?.id;

    if (!slugOrId) {
      return;
    }

    this.menuItems = this.menuItems.map((item) =>
      replaceUserNamePatternWithSlug(item, slugOrId),
    );
  }
}
