import { makeAutoObservable } from 'mobx';
import { Member } from '@wix/ambassador-members-v1-member/types';

import {
  ContextServices,
  Nullable,
  RouteConfiguration,
  StateService as IStateService,
  WixCodeApi,
} from '../../../../types';
import { INITIAL_WARMUP_DATA } from '../../../../constants';

type WarmupData = Pick<
  IStateService,
  'currentUser' | 'currentUserRoles' | 'routes'
>;

type ContextProps = { wixCodeApi: WixCodeApi; isSSR: boolean };

export class StateService implements IStateService {
  routes: RouteConfiguration[] = [];
  currentUser: Nullable<Member> = null;
  currentUserRoles: string[] = [];

  constructor(
    private readonly contextProps: ContextProps,
    private readonly contextServices: Pick<
      ContextServices,
      | 'cacheService'
      | 'currentUserService'
      | 'rolesService'
      | 'warmupDataService'
    >,
  ) {
    makeAutoObservable(this);
  }

  async fetchCurrentUser() {
    const { cacheService, currentUserService } = this.contextServices;
    if (cacheService.hasCurrentUser()) {
      this.currentUser = cacheService.getCurrentUser();
      return;
    }

    this.currentUser = await currentUserService.getCurrentUser();
    cacheService.setCurrentUser(this.currentUser!);
  }

  async fetchCurrentUserRoles() {
    const { cacheService, rolesService } = this.contextServices;
    if (cacheService.hasRoles()) {
      this.currentUserRoles = cacheService.getRoles();
      return;
    }

    this.currentUserRoles = await rolesService.getMemberRoles(
      this.currentUser?.id ?? null,
    );
    cacheService.setRoles(this.currentUserRoles!);
  }

  async fetchRouteConfigurations() {
    const { cacheService } = this.contextServices;
    if (cacheService.hasRoutes()) {
      this.routes = cacheService.getRoutes();
      return;
    }

    this.routes = [] as RouteConfiguration[];
    cacheService.setRoutes(this.routes);
  }

  async fetchInitialData() {
    const { isSSR } = this.contextProps;

    if (isSSR) {
      await this.fetchDataWrapper();
      const { currentUser, currentUserRoles, routes } = this;
      return this.contextServices.warmupDataService.setData(
        INITIAL_WARMUP_DATA,
        { currentUser, currentUserRoles, routes },
      );
    }

    const warmupData =
      this.contextServices.warmupDataService.getData<WarmupData>(
        INITIAL_WARMUP_DATA,
      );

    if (warmupData) {
      this.currentUser = warmupData.currentUser;
      this.currentUserRoles = warmupData.currentUserRoles;
      this.routes = warmupData.routes;
      return;
    }

    return this.fetchDataWrapper();
  }

  private async fetchDataWrapper() {
    await this.fetchCurrentUser();
    await Promise.all([
      this.fetchRouteConfigurations(),
      this.fetchCurrentUserRoles(),
    ]);
  }
}
